<template>
  <div class="app-container">
    <header-menu :showTitle="false" logoTitle="企业中心">
      <template slot="right" v-if="$store.state.user.token"></template>
      <template slot="right" v-else></template>
    </header-menu>
    <div class="a-cont">
      <div class="bgImgBox">确认公司，开始招聘</div>
      <div class="g-wrap">
        <el-steps :active="active" finish-status="success" simple style="margin: 20px 0;width: 800px;">
          <el-step title="完善个人信息"></el-step>
          <el-step title="确认公司信息"></el-step>
          <el-step title="员工身份认证"></el-step>
        </el-steps>
        <div class="cinfo-box" v-show="active == 0">
          <div class="info-left">
            <el-form v-if="isLoaded" :model="personalForm" status-icon :rules="rules" ref="personalForm" label-width="110px" class="demo-personalForm">
              <el-form-item label="公司全称" prop="EnterpriseName">
                <el-autocomplete style="width: 100%" v-model="personalForm.EnterpriseName" value-key="EnterpriseName" :fetch-suggestions="querySearch" placeholder="请填写与营业执照名称/劳动合同/公司发票抬头一致的公司全称" @select="handleSelect"
                  @blur="blurName"></el-autocomplete>
              </el-form-item>
              <el-form-item label="姓名" prop="Name">
                <el-input v-model="personalForm.Name" placeholder="请填写你工作中的姓名，用于向求职者展示" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item label="职位" prop="Position">
                <el-input v-model="personalForm.Position" placeholder="请填写当前公司的任职职位" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item label="接受简历邮箱" prop="Email">
                <el-input v-model="personalForm.Email" placeholder="请填写常用邮箱，用于接受简历"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="next1" :loading="btnLoading">下一步</el-button>
              </el-form-item>
            </el-form>
          </div>
          <!-- <div class="info-right"></div> -->
        </div>
        <div class="cinfo-box" v-show="active == 1">
          <div class="">
            <el-form v-if="isLoaded" :model="companyForm" status-icon :rules="rules" ref="companyForm" label-width="110px" class="demo-companyForm">
              <el-form-item label="公司全称" prop="EnterpriseName">
                <el-input v-model="companyForm.EnterpriseName" disabled autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item label="信用代码" prop="Code">
                <el-input v-model="companyForm.Code" placeholder="请填写信用代码" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item label="公司logo" prop="Logo">
                <picture-selector v-model="companyForm.Logo" name="Logo" ref="Logo" remarks="200*200" uploadPath="/DataCenterFile/UploadSingleFile" :width="100" :height="100" @input="updateForm2">
                </picture-selector>
              </el-form-item>
              <el-form-item label="公司行业" prop="Industry">
                <el-cascader v-model="companyForm.Industry" :show-all-levels="false" :props="industryProps" :options="industryOptions" style="width:100%" placeholder="请选择所属行业">
                </el-cascader>
              </el-form-item>
              <el-form-item label="企业性质" prop="Nature">
                <el-select v-model="companyForm.Nature" placeholder="请选择">
                  <el-option v-for="item in natureOptions" :key="item.Value" :label="item.Name" :value="item.Value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="公司位置" prop="Area">
                <el-cascader v-model="companyForm.Area" :props="props" :options="areaTree" style="width:100%" placeholder="请选择所属地区" @change="areaChange">
                </el-cascader>
              </el-form-item>
              <el-form-item label="详细地址" prop="Address">
                <el-input v-model="companyForm.Address" placeholder="请填写详细地址" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item label="公司规模" prop="Scale">
                <el-select v-model="companyForm.Scale" placeholder="请选择">
                  <el-option v-for="item in scaleOptions" :key="item.Id" :label="item.Name" :value="item.Value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="公司简介">
                <rich-text v-model="companyForm.Introduction" ref="Introduction" name="Introduction" uploadPath="/DataCenterFile/UploadSingleFile" @input="updateForm2"></rich-text>
              </el-form-item>
              <el-form-item>
                <el-button type="info" @click="active--">上一步</el-button>
                <el-button type="primary" @click="next2" :loading="btnLoading">下一步</el-button>
              </el-form-item>
            </el-form>
          </div>
          <!-- <div class="info-right"></div> -->
        </div>
        <div class="cinfo-box" v-show="active == 2">
          <div class="">
            <el-form :model="thirdForm" status-icon :rules="rules" ref="thirdForm" label-width="110px" class="demo-thirdForm">
              <el-form-item label="营业执照" prop="BusinessLicense">
                <picture-selector v-model="thirdForm.BusinessLicense" name="BusinessLicense" ref="BusinessLicense" remarks="297*420" uploadPath="/DataCenterFile/UploadSingleFile" :width="297" :height="420"
                  @input="updateForm3">
                </picture-selector>
              </el-form-item>
              <el-form-item label="真实姓名" prop="RealName">
                <el-input v-model="thirdForm.RealName" placeholder="请填写本人真实姓名" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item label="身份证号" prop="IdNumber">
                <el-input v-model="thirdForm.IdNumber" placeholder="请填写本人身份证号码" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="info" @click="active--">上一步</el-button>
                <el-button type="primary" @click="finish">完成</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderMenu from "@/components/Common/HeaderMenu";
import PictureSelector from "@/components/base/PictureSelector";
import RichText from "@/components/base/RichText";
import {
  createEntity,
  getEnterpriseAndStaffByUserId,
  updateEntity
} from "@/api/enterprise/enterpriseStaff";
import {
  getPageList,
  getAttributeOptions,
  updateEntity as updateEnterpriseInfo,
  submitInfo
} from "@/api/enterprise/enterpriseInfo";
export default {
  components: { HeaderMenu, PictureSelector, RichText },
  data() {
    return {
      personalForm: {
        Name: "",
        Position: "",
        Email: "",
        EnterpriseName: "",
        UserId: this.$store.getters.userId,
        Id: ""
      },
      companyForm: {
        Id: "",
        EnterpriseBaseId: "",
        EnterpriseName: "",
        BusinessLicense: "",
        Code: "",
        EstablishTime: "",
        Nature: "",
        Industry: "",
        City: "",
        Scale: "",
        Logo: "",
        Summary: "",
        Introduction: "",
        Area: []
      },
      thirdForm: {
        RealName: "",
        IdNumber: "",
        BusinessLicense: "",
        UnitId: "",
        StaffId: ""
      },
      rules: {
        Name: [{ required: true, message: "此项为必填项", trigger: "blur" }],
        Position: [
          { required: true, message: "此项为必填项", trigger: "blur" }
        ],
        Email: [{ required: true, message: "此项为必填项", trigger: "blur" }],
        EnterpriseName: [
          {
            required: true,
            message: "此项为必填项",
            trigger: ["blur", "change"]
          }
        ],
        BusinessLicense: [
          { required: true, message: "请上传营业执照", trigger: "blur" }
        ],
        IdNumber: [
          { required: true, message: "此项为必填项", trigger: "blur" }
        ],
        Code: [{ required: true, message: "此项为必填项", trigger: "blur" }],
        Logo: [{ required: true, message: "此项为必填项", trigger: "blur" }],
        CoIndustryde: [
          { required: true, message: "此项为必填项", trigger: "blur" }
        ],
        Nature: [{ required: true, message: "此项为必填项", trigger: "blur" }],
        Industry: [
          { required: true, message: "此项为必填项", trigger: "blur" }
        ],
        Area: [{ required: true, message: "此项为必填项", trigger: "blur" }],
        Address: [{ required: true, message: "此项为必填项", trigger: "blur" }],
        Scale: [{ required: true, message: "此项为必填项", trigger: "blur" }],
        RealName: [{ required: true, message: "此项为必填项", trigger: "blur" }]
      },
      active: 0,
      options: [],
      loading: false,
      btnLoading: false,
      registered: false,
      industryOptions: [],
      areaTree: [],
      props: {
        value: "Code",
        label: "Name",
        children: "Children"
      },
      natureOptions: [],
      scaleOptions: [],
      isLoaded: false,
      fileList: [],
      baseApi: process.env.VUE_APP_BASE_API,
      keyWord: "",
      industryProps: {
        value: "Id",
        label: "Name",
        children: "Children"
      }
    };
  },
  async mounted() {
    getEnterpriseAndStaffByUserId(this.$store.getters.userId).then(res => {
      if (res.IsSuccess) {
        this.isLoaded = true;
        if (res.Result.Staff) {
          this.thirdForm.StaffId = res.Result.Staff.Id;
          this.registered = true; //当前用户已注册过公司
          this.personalForm = res.Result.Staff;
          this.companyForm = res.Result.Enterprise;
          this.personalForm.EnterpriseName = this.companyForm.EnterpriseName;
          this.thirdForm.UnitId = this.companyForm.Id;
          this.companyForm.Area = (
            res.Result.Enterprise.ProvinceCode +
            "," +
            res.Result.Enterprise.CityCode +
            "," +
            res.Result.Enterprise.RegionCode
          ).split(",");
        }
      }
    });
    getAttributeOptions().then(res => {
      if (res.IsSuccess) {
        this.industryOptions = res.Result.industryOptions;
        this.natureOptions = res.Result.natureOptions;
        this.scaleOptions = res.Result.scaleOptions;
        this.areaTree = this.deleteChildren(res.Result.areaTree.Children);
      }
    });
  },
  methods: {
    next() {
      this.active++;
    },
    // 下一步(完善个人信息)
    next1() {
      this.$refs["personalForm"].validate(valid => {
        if (valid) {
          this.btnLoading = true;
          var postForm = Object.assign({}, this.personalForm);
          if (this.registered) {
            //已注册走更新接口
            updateEntity(postForm).then(res => {
              this.btnLoading = false;
              if (res.IsSuccess) {
                getEnterpriseAndStaffByUserId(this.$store.getters.userId).then(
                  res => {
                    if (res.IsSuccess) {
                      this.isLoaded = true;
                      if (res.Result.Staff) {
                        this.thirdForm.StaffId = res.Result.Staff.Id;
                        this.registered = true; //当前用户已注册过公司
                        this.personalForm = res.Result.Staff;
                        this.companyForm = res.Result.Enterprise;
                        this.personalForm.EnterpriseName = this.companyForm.EnterpriseName;
                        this.thirdForm.UnitId = this.companyForm.Id;
                        this.companyForm.Area = (
                          res.Result.Enterprise.ProvinceCode +
                          "," +
                          res.Result.Enterprise.CityCode +
                          "," +
                          res.Result.Enterprise.RegionCode
                        ).split(",");
                      }
                    }
                  }
                );
                this.active++;
              }
            });
          } else {
            //未注册走新增接口
            delete postForm.Id;
            createEntity(postForm).then(res => {
              this.btnLoading = false;
              if (res.IsSuccess) {
                getEnterpriseAndStaffByUserId(this.$store.getters.userId).then(
                  res => {
                    if (res.IsSuccess) {
                      this.isLoaded = true;
                      if (res.Result.Staff) {
                        this.thirdForm.StaffId = res.Result.Staff.Id;
                        this.registered = true; //当前用户已注册过公司
                        this.personalForm = res.Result.Staff;
                        this.companyForm = res.Result.Enterprise;
                        this.personalForm.EnterpriseName = this.companyForm.EnterpriseName;
                        this.thirdForm.UnitId = this.companyForm.Id;
                        this.companyForm.Area = (
                          res.Result.Enterprise.ProvinceCode +
                          "," +
                          res.Result.Enterprise.CityCode +
                          "," +
                          res.Result.Enterprise.RegionCode
                        ).split(",");
                      }
                    }
                  }
                );
                // this.companyForm.Id = res.Result;
                // this.companyForm.EnterpriseName = postForm.EnterpriseName;
                this.thirdForm.UnitId = res.Result;
                this.active++;
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    // 下一步(确认公司信息
    next2() {
      this.$refs["companyForm"].validate(valid => {
        if (valid) {
          this.btnLoading = true;
          var postForm = Object.assign({}, this.companyForm);
          postForm.Industry = this.companyForm.Industry[1];
          updateEnterpriseInfo(postForm).then(res => {
            this.btnLoading = false;
            if (res.IsSuccess) {
              this.active++;
            }
          });
        } else {
          return false;
        }
      });
    },
    finish() {
      this.$refs["thirdForm"].validate(valid => {
        if (valid) {
          var postForm = Object.assign({}, this.thirdForm);
          submitInfo(postForm).then(res => {
            if (res.IsSuccess) {
              alert("企业认证审核中，审核通过即可进入");
              this.$router.push(`/`);
            }
          });
        } else {
          return false;
        }
      });
    },
    updateForm(fieldName, value) {
      console.log("修改参数:", fieldName + "值：", value);
      this.personalForm[fieldName] = value;
    },
    updateForm2(fieldName, value) {
      console.log("修改参数:", fieldName + "值：", value);
      this.companyForm[fieldName] = value;
    },
    updateForm3(fieldName, value) {
      console.log("修改参数:", fieldName + "值：", value);
      this.thirdForm[fieldName] = value;
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    deleteChildren(arr) {
      const childs = arr;
      for (let i = childs.length; i--; i > 0) {
        if (childs[i].Children) {
          if (childs[i].Children.length) {
            this.deleteChildren(childs[i].Children);
          } else {
            delete childs[i].Children;
          }
        }
      }
      return arr;
    },
    areaChange(value) {
      this.companyForm.ProvinceCode = value[0];
      this.companyForm.CityCode = value[1];
      this.companyForm.RegionCode = value[2];
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    // 查询企业列表 返回输入建议
    querySearch(queryString, cb) {
      setTimeout(() => {
        var info = {
          KeyWord: queryString
        };
        getPageList(info).then(res => {
          this.options = res.Result.Data;
          cb(this.options);
        });
      }, 200);
    },
    // 选择建议中的某个企业
    handleSelect(item) {
      this.personalForm.EnterpriseId = item.Id;
    },
    // 企业名称输入完成，输入框失焦
    blurName() {
      var enterprise = this.options.find(
        p => p.EnterpriseName == this.personalForm.EnterpriseName
      );
      if (enterprise) {
        this.personalForm.EnterpriseId = enterprise.Id;
      } else {
        this.personalForm.EnterpriseId = "";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.a-cont {
  margin-top: 64px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  .g-wrap {
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
.bgImgBox {
  width: 100%;
  height: 140px;
  line-height: 140px;
  background-color: #598DF3;
  font-size: 32px;
  text-align: center;
  color: #fff;
}
.step {
  width: 360px;
  display: flex;
  justify-content: space-between;
  margin: 48px 0;
  .step-box {
    text-align: center;
    font-size: 16px;
    color: #555;
    .num {
      font-size: 24px;
      margin-bottom: 12px;
      font-weight: bold;
      color: #00cb8a;
    }
  }
  .color-gray {
    color: #b5b5b5;
    .num {
      color: #999;
    }
  }
  .line {
    height: 17px;
    // background-color: #666;
    border-bottom: 1px solid #999;
    width: 100px;
  }
}
.cinfo-box {
  width: 900px;
  margin: 0 100px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .info-left {
    height: 400px;
    width: 620px;
  }
  .info-right {
    width: 180px;
    height: 200px;
    border: 1px solid #999;
  }
}

.upload-text {
  font-size: 14px;
  color: #999;
  width: 296px;
  float: left;
  padding: 20px 0 0;
  line-height: 25px;
}
</style>
<style lang="scss">
.avatar-uploader {
  width: 128px;
  height: 100px;
  float: left;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #00cb8a;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
</style>
